<template>
  <div>
    <Tabs v-model="currenttable">
      <TabPane
        label="新素材上传"
        name="newScreen"
      >
        <material-upload></material-upload>
      </TabPane>
      <TabPane
        label="素材提交记录"
        name="history"
        v-if="approvalList.length"
      >
        <material-history v-if="approval"></material-history>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import MaterialUpload from './MaterialUpload'
import MaterialHistory from './MaterialHistory'
export default {
  components: {
    MaterialUpload,
    MaterialHistory
  },
  computed: {
    approvalList () {
      return this.$store.state.task.approvalFileList
    },
    currenttable: {
      get () {
        return this.$store.state.task.materialManageTab
      },
      set (val) {
        this.$store.commit('set_material_manage_tab', val)
      }
    },
    approval () {
      return this.$store.state.task.approvalFile
    }
  },
  destroyed () {
    this.currenttable = 'newScreen'
  }
}
</script>
